.fs-body {
  font-size: var(--sizeBase);
}

.btn-body {
  background-color: var(--colorBody) !important;
  color: var(--colorWhite);
}
.btn-body:hover {
  background-color: var(--colorBodyHover) !important;
  color: var(--colorWhite);
}

.border-transparent {
  border-color: transparent !important;
}
.fs-inherit {
  font-size: inherit;
}
.min-vh {
  min-height: calc(100vh - 400px);
}
.btn-min-vh {
  padding-bottom: 65px;
  position: relative;
}
.btn-min-vh .btn {
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 1;
  transform: translateX(-50%);
}

.max-400 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
}
.max-375 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 375px;
}
.max-375 .btn {
  max-width: 375px;
}

.fixed-button-bottom {
  padding-bottom: 65px;
  min-height: calc(
    100vh - 180px - 3rem * 2
  ); /*min-height:calc(100vh - 120px - 3rem * 2);*/ /*min-height:calc(100vh - 71px - 3rem * 2);*/
  position: relative;
}
.fixed-button-bottom .btn {
  position: absolute;
  left: 50%;
  bottom: 0px;
  z-index: 1;
  transform: translateX(-50%);
}

.heading-wrapper {
}
.heading-wrapper .title {
  line-height: 1.3;
}

.back-to-wrapper {
  /*padding:0px 60px; position:relative;*/
}
.back-to-wrapper .back-to {
  /*font-size:40px;*/
  left: 0px;
  top: 5px;
}
.back-to-wrapper .back-to i {
}

ul.dotted-line-style {
  list-style: none;
  display: table;
  padding: 0px 0px;
  margin: 0px auto;
}
ul.dotted-line-style li {
  padding-left: 30px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
}
ul.dotted-line-style li:before {
  content: "";
  background: var(--colorSecondary);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 5px;
  left: 0px;
  border-radius: 50px;
}
ul.dotted-line-style li:after {
  content: "";
  border-right: 1px dashed var(--colorBody);
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 4px;
  z-index: -1;
}
ul.dotted-line-style li:first-child:after {
  top: 10px;
}
ul.dotted-line-style li:last-child:after {
  display: none;
}

.mobile-body {
  /*display:flex; flex-wrap:wrap; justify-content:space-between; flex-direction:column; min-height:100vh;*/
}
.mobile-body header {
  width: 100%;
}
.mobile-body .body-content {
  /*flex:1;*/
  width: 100%;
}

.upload-file-wrapper {
  position: relative;
}
.upload-file-wrapper input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  cursor: pointer;
}
.upload-file-wrapper .btn {
}
.upload-file-wrapper:hover .btn-outline-secondary {
  background-color: var(--colorSecondary) !important;
  color: var(--colorWhite);
}

.questionnaire-section {
}
.questionnaire-section .checkbox-wrapper .form-check {
  width: calc(33.33% - 1.5rem);
}

.upload-file-dropdown {
}
.upload-file-dropdown ul.dropdown-menu {
  background-color: #444;
  padding: 0px 0px;
  margin-top: -3px !important;
  width: 100%;
  max-width: 240px;
  left: auto !important;
  right: 0px !important;
}
.upload-file-dropdown ul.dropdown-menu li {
  position: relative;
}
.upload-file-dropdown ul.dropdown-menu li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}
.upload-file-dropdown ul.dropdown-menu li input[type="file"] {
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: default;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.upload-file-dropdown ul.dropdown-menu li a {
  color: var(--colorWhite);
  text-decoration: none;
  padding: 0.75rem 3rem 0.75rem 1rem;
  position: relative;
}
.upload-file-dropdown ul.dropdown-menu li a i {
  font-size: var(--sizeLarge);
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 1rem;
  z-index: 1;
  transform: translateY(-50%);
}

.file-uploading-wrapper {
}
.file-uploading-wrapper .left-wrapper {
  width: 40px;
}
.file-uploading-wrapper .left-wrapper img {
  width: 100%;
  height: auto;
}
.file-uploading-wrapper .right-wrapper {
  flex: 1;
  padding-left: 1rem;
}
.file-uploading-wrapper .progress {
  background-color: var(--colorGray);
  height: 3px;
}
.file-uploading-wrapper .progress .progress-bar {
}

.uploaded-file-wrapper {
}
.uploaded-file-wrapper .left-wrapper {
  width: 40px;
}
.uploaded-file-wrapper .left-wrapper img {
}
.uploaded-file-wrapper .right-wrapper {
  flex: 1;
  padding-left: 1rem;
}

ul.check-style {
  list-style: none;
  display: table;
  padding: 0px 0px;
  margin: 0px auto;
}
ul.check-style li {
  padding-left: 30px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;
}
ul.check-style li:before {
  content: "\F26A";
  font-family: var(--fontBootstrapIcon);
  font-size: 1.3rem;
  color: var(--colorPrimary);
  position: absolute;
  top: -4px;
  left: 0px;
  border-radius: 50px;
}

.thumb-img-wrapper {
}
.thumb-img-wrapper a:not(:last-child) {
  margin-right: 1rem;
}
.thumb-img-wrapper img {
  width: 90px;
  min-width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: var(--sizeExtraSmall);
  transition: var(--transition);
}
.thumb-img-wrapper img:not(:last-child) {
  margin-right: 1rem;
}
.thumb-img-wrapper a.img-file {
}
.thumb-img-wrapper a.img-file img {
  object-fit: contain;
  width: 70px;
  min-width: 70px;
}

.img-70 {
  width: 70px;
  height: 70px;
  object-fit: cover;
}
.cursor-default {
  cursor: default;
}

#baguetteBox-overlay {
}

#baguetteBox-overlay #baguetteBox-slider {
}
#baguetteBox-overlay #baguetteBox-slider .full-image {
  padding: 0px 30px;
}
#baguetteBox-overlay #baguetteBox-slider .full-image figure {
}
#baguetteBox-overlay #baguetteBox-slider .full-image figure img {
}

#baguetteBox-overlay .baguetteBox-button {
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 35px !important;
  height: 35px !important;
  border-radius: 0px;
}
#baguetteBox-overlay .baguetteBox-button svg {
  display: none;
}
#baguetteBox-overlay .baguetteBox-button:before {
  font-family: var(--fontBootstrapIcon);
}
#baguetteBox-overlay #previous-button {
  left: 30px;
}
#baguetteBox-overlay #previous-button:before {
  content: "\F284";
}
#baguetteBox-overlay #next-button {
  right: 30px;
}
#baguetteBox-overlay #next-button:before {
  content: "\F285";
}
#baguetteBox-overlay #close-button {
  background-color: var(--colorTransparent);
  right: 20px;
}
#baguetteBox-overlay #close-button:before {
  content: "\F659";
}

.second-opinion-card {
}
.second-opinion-card ul.second-opinion-steps {
}
.second-opinion-card ul.second-opinion-steps li {
}
.second-opinion-card ul.second-opinion-steps li i {
  top: -3px;
}
.second-opinion-card ul.second-opinion-steps li.complete {
}
.second-opinion-card ul.second-opinion-steps li.complete i {
  color: var(--colorPrimary);
}

ul.instructions-list {
}
ul.instructions-list li {
  padding-left: 35px;
}
ul.instructions-list li i {
  background: var(--colorGold);
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 24px;
  height: 24px;
  top: -2px;
  border-radius: 50%;
}

ul#step-tab li a {
  background-color: var(--colorWhite);
  color: var(--colorMuted);
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--colorMuted);
  width: 35px;
  height: 35px;
}
ul#step-tab li a span {
}
ul#step-tab li a .num {
  margin-top: -2px;
}
ul#step-tab li a .value {
  line-height: 1.2;
  white-space: nowrap;
}
ul#step-tab li a .value br {
  display: none;
}

ul#step-tab li > a:not(.rounded-pill) {
  background-color: var(--colorTransparent);
  border: none;
  width: auto;
  height: auto;
}

ul#step-tab li a.active {
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li a.active .num {
}
ul#step-tab li a.active .value {
}

ul#step-tab li:nth-child(1) a.complete {
  background-color: var(--colorPrimary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(2) a.complete {
  background-color: var(--colorSecondary);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(3) a.complete {
  background-color: var(--colorViolet);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}
ul#step-tab li:nth-child(4) a.complete {
  background-color: var(--colorViolet);
  color: var(--colorWhite);
  border-color: var(--colorTransparent);
}

.consent-form {
}
.consent-form .form-group {
}
.consent-form .form-group .form-label {
}
.consent-form .form-group .form-control {
  line-height: var(--bodyLineHeight);
  display: inline-block;
  vertical-align: top;
  padding: 0px 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  width: auto;
  border-radius: 0px;
}
.consent-form .form-group ul.check-list {
}
.consent-form .form-group ul.check-list li {
}
.consent-form .form-group ul.check-list li .form-check-input {
  margin-top: -2px;
  margin-right: 12px;
}
.consent-form .form-group ul.check-list li .form-check-label {
  line-height: var(--bodyLineHeight);
  align-content: flex-start;
}
.consent-form-date-input {
  width: 115px !important;
}

ul.treatment-list {
}
ul.treatment-list li {
}
ul.treatment-list li > a {
}
ul.treatment-list li > a > div {
  border: 1px solid var(--colorSecondary) !important;
  border-radius: var(--sizeSmall);
}
ul.treatment-list li > a.active {
}
ul.treatment-list li > a.active > div {
  background-color: var(--colorSecondary);
  color: var(--colorWhite);
}

.second-opinion-schedule .select-slot .owl-item .open {
  background: var(--colorSecondary);
}
.second-opinion-schedule .slot-box.active,
.second-opinion-schedule .slot-box:hover {
  background: var(--colorSecondary);
}
.second-opinion-schedule .select-slot .item {
  line-height: normal;
}

/* .second-opinion-schedule .select-date input[type="date"] { background:url('../images/icon-input-calendar.svg'); background-repeat:no-repeat; background-size:auto 18px; background-position:center right; text-align:right; } */
.second-opinion-schedule .select-date input[type="date"] {
  background-repeat: no-repeat;
  background-size: auto 18px;
  background-position: center right;
  text-align: right;
}
.second-opinion-schedule
  .select-date
  input[type="date"]::-webkit-inner-spin-button,
.second-opinion-schedule
  .select-date
  input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  margin-left: 3px;
  margin-right: -11px;
  width: 20px;
  height: 20px;
}

.signature-parent {
  background-color: var(--bodyBackground);
  border: 1px solid var(--colorGray);
}
.signature-parent .signature {
  height: 80px;
}
.signature-parent .signature img {
}

.payment-detail-section {
}
.payment-detail-section .email-group {
}
.payment-detail-section .email-group label {
}
.payment-detail-section .email-group .form-control {
  padding-left: 70px;
}
.payment-detail-section .payment-card-fields {
}
.payment-detail-section .payment-card-fields > *:focus {
  z-index: 1;
}
.payment-detail-section .payment-card-fields .payment-card-number {
  background-image: url("../images/payment-card-icons.png");
  background-repeat: no-repeat;
  background-size: 140px auto;
  background-position: center right 0.675rem;
  padding-right: 160px;
  margin-bottom: -1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.payment-detail-section .payment-card-fields .payment-card-date {
  margin-right: -1px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: calc(50% + 1px) !important;
}
.payment-detail-section .payment-card-fields .payment-card-cvc {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.payment-detail-section .region-fields {
}
.payment-detail-section .region-fields > * {
  position: relative;
  z-index: 0;
}
.payment-detail-section .region-fields > *:focus {
  z-index: 1;
}
.payment-detail-section .region-fields select {
  margin-bottom: -1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.payment-detail-section .region-fields input {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

@media only screen and (min-width: 992px) {
  .max-960 {
    margin-left: auto;
    margin-right: auto;
    max-width: 960px;
  }
}

@media only screen and (max-width: 991px) {
  .fixed-button-bottom {
    min-height: calc(100vh - 170px - 3rem * 2);
  }
  .questionnaire-section .checkbox-wrapper .form-check {
    width: calc(50% - 1.5rem);
  }
}

@media only screen and (max-width: 767px) {
  .fixed-button-bottom {
    padding-bottom: 50px;
    min-height: calc(100vh - 150px - 56px - 24px - 1.5rem * 2);
  }
  .back-to-wrapper .back-to {
  }

  .thumb-img-wrapper img {
    width: 78px;
    min-width: 78px;
    height: 78px;
  }
  .thumb-img-wrapper img:not(:last-child) {
    margin-right: 0.75rem;
  }

  .second-opinion-card ul.second-opinion-steps li i {
    top: -2px;
  }

  ul#step-tab li a {
    width: 30px;
    height: 30px;
  }
  ul#step-tab li a .value br {
    display: block;
  }
  .thumb-img-wrapper a.img-file img {
    object-fit: contain;
    width: 60px;
    min-width: 60px;
  }
  ul.treatment-list li > a > div {
    border-radius: var(--sizeExtraSmall);
  }

  .payment-detail-section .email-group .form-control {
    padding-left: 60px;
  }
  .payment-detail-section .payment-card-fields .payment-card-number {
    background-size: 85px auto;
    padding-right: 100px;
  }
}

@media only screen and (max-width: 479px) {
  .questionnaire-section .checkbox-wrapper .form-check {
    width: calc(100% - 1.5rem);
  }
}

.gth-upload:focus + .second-opinion-upload {
  border-color: var(--colorSecondary) !important;
}

.gth-upload:focus + .second-opinion-record {
  background-color: #869791 !important;
}

.second-opinion-record {
  border: 0 !important;
}

.second-opinion-record p:focus {
  border: 0 !important;
}

.second-opinion-upload {
  display: flex;
  border: 2px dashed var(--colorBorder);
}

.btn-close:focus {
  border: 2px solid black !important;
  opacity: 1 !important;
}
